import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    shopFeaturesUpdate : 'SHOP_FEATURES_UPDATE',
    shopFeaturesDelete : 'SHOP_FEATURES_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        shopFeatures: [],
        shopFeaturesLoading: false,
        shopFeaturesError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_SHOP_FEATURES (state, shopFeatures) {
            state.shopFeatures = shopFeatures;
        },

        SHOP_FEATURES_LOADING (state, loading) {
            state.shopFeaturesLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateShopFeatures({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.shopFeaturesUpdate,
                async () => {
                    let payloadData={
                        title: payload.title,
                        icon: payload.icon
                    }
                    let magic=encode(payloadData)
                    response = await axios.post(V1API.shop_features_update+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchShopFeatures');

                }
            )
            return response
        },



        async deleteShopFeatures({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.shopFeaturesDelete,
                async () => {

                    response = await axios.delete(V1API.shop_features_delete+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchShopFeatures');
                }
            )

            return response
        },

        async fetchShopFeatures ({ commit },payload) {
            commit('SHOP_FEATURES_LOADING', true);
            commit('SHOP_FEATURES_ERROR', false);
            try{

                let result = await axios.get(V1API.shop_features_read);
                let response=decode(result.data)
                commit('SET_SHOP_FEATURES', response.data);

            }catch(error) {

                commit('SHOP_FEATURES_ERROR', true);

            }
            commit('SHOP_FEATURES_LOADING', false);
        },
        async addShopFeatures({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.shop_features_add,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchShopFeatures')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
