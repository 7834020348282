export default [
    {
        path: '/dashboard/business-management/onboarded-business',
        name: 'business-list',
        component: () => import('@/views/pages/nextcart/businessManagement/BusinessList.vue'),
        meta:{
            codename:'view_businesscore'
        }

    },
    {
        path: '/dashboard/business-management/business-onboarding',
        name: 'business-add',
        component: () => import('@/views/pages/nextcart/businessManagement/BusinessAdd.vue'),
        meta:{
            codename:'view_businesscore'
        }

    },
    // {
    //     path: '/dashboard/theme-management/themes',
    //     name: 'theme',
    //     component: () => import('@/views/pages/nextcart/themeManagement/ThemeTable.vue'),
    //     meta:{
    //         codename:'view_theme'
    //     }
    // },
    // {
    //     path: '/dashboard/database/state',
    //     name: 'database-state',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/StateTable'),
    //     meta:{
    //         codename:'view_state'
    //     }
    // },
    // {
    //     path: '/dashboard/database/country',
    //     name: 'database-country',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/CountryTable'),
    //     meta:{
    //         codename:'view_country'
    //
    //     }
    //
    // }
]
