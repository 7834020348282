// var hostUrl = "http://127.0.0.1:8000";
var hostUrl = "https://mother.nextcart.shop";
var host = hostUrl + "/api/";
// var host = "http://127.0.0.1:8000/api/";,

module.exports = {
    host: hostUrl,
    V1API: {
        'getAccessToken': host + 'authentication/token',
        'refreshAccessToken': host + 'authentication/token/refresh',
        'verifyLogin': host + 'authentication/token/verify',

        // 'password_reset_request':host+'/api/password_reset/',
        // 'validate_reset_token':host+'/api/password_reset/validate_token/',
        // 'reset_password':host+'/api/password_reset/confirm/',

        'businessTypes_add': host + 'business/business-type/create',
        'businessTypes_edit': host + 'business/business-type/update',
        'businessTypes_get': host + 'business/business-types',
        'businessTypes_delete': host + 'business/business-type/delete',


        'reservedSlug_add': host + 'business/business-slug/create',
        'reservedSlug_edit': host + 'business/business-slug/update',
        'reservedSlug_get': host + 'business/business-slugs',
        'reservedSlug_delete': host + 'business/business-slug/delete',

        'country_add': host + 'location/country/create',
        'country_edit': host + 'location/country/update',
        'country_get': host + 'location/country/read',
        'country_delete': host + 'location/country/delete',

        'state_add': host + 'location/state/create',
        'state_edit': host + 'location/state/update',
        'state_get': host + 'location/state/read',
        'state_delete': host + 'location/state/delete',

        'state_by_country_get': host + 'location/state/read/country',

        'area_add': host + 'location/area/create',
        'area_edit': host + 'location/area/update',
        'area_get': host + 'location/area/read',
        'area_delete': host + 'location/area/delete',

        'area_by_state_get': host + 'location/area/read/state',


        'business_add': host + 'business/business/create',
        'business_delete': host + 'business/business/delete',
        'business_edit': host + 'business/business/update',
        'business_get': host + 'business/business/all/test',
        'business_simple': host + 'business/business/read/simple',
        'business_feature_status_toggle': host + 'business/business/featured/toggle',

        'theme_type_add': host + 'theme/type/create',
        'theme_type_edit': host + 'theme/type/update',
        'theme_type_get': host + 'theme/types',
        'theme_type_delete': host + 'theme/type/delete',

        'theme_add': host + 'theme/create',
        'theme_edit': host + 'theme/update',
        'theme_get': host + 'theme/list',
        'theme_delete': host + 'theme/delete',
        'theme_toggle': host + 'theme/feature/toggle',

        'deployment_url_add': host + 'deployment/urls/create',
        'deployment_url_edit': host + 'deployment/urls/update',
        'deployment_url_get': host + 'deployment/urls',
        'deployment_deploy': host + 'deployment/deploy',
        'deploymentUrl_delete': host + 'deployment/urls/delete',

        'server_list_add': host + 'server/create',
        'server_list_edit': host + 'server/update',
        'server_list_get': host + 'server/list',
        'server_list_delete': host + 'server/delete',


        //onboarding
        'subscriber_create': host + 'access-control/subscriber/create',
        'business_create': host + 'business/business/create',
        'select_theme': host + 'theme/select/',
        'deploy_instance': host + 'deployment/deploy',
        // 'deploy_instance': host + 'deployment/deploy/test',
        'deploy_urls_create': host + 'deployment/urls/create',
        'select_subscription': host + 'subscription/select/',

        //affiliate
        'affiliate_list': host + 'affiliate/get/all',
        'affiliate_list_simple': host + 'affiliate/get/list',
        'affiliate_create': host + 'access-control/affiliate/create',
        'affiliate_data_create': host + 'affiliate/create',
        // 'business_create': host + 'business/business/create',
        // 'select_theme': host + 'theme/select/',
        // 'deploy_instance': host + 'deployment/deploy',
        // 'deploy_instance': host + 'deployment/deploy/test',
        // 'deploy_urls_create': host + 'deployment/urls/create',
        // 'select_subscription': host + 'subscription/select/',


        //limit
        'day_limit_add': host + 'subscription/day/limit/create',
        'day_limit_edit': host + 'subscription/day/limit/update',
        'day_limit_get': host + 'subscription/day/limits',
        'day_limit_delete': host + 'subscription/day/limit/delete',

        //permission-group
        'permission_group_add': host + 'subscription/permission-group/create',
        'permission_group_edit': host + 'subscription/permission-group/update',
        'permission_group_get': host + 'subscription/permission-group',
        'permission_group_get_json': host + 'subscription/permission-group/json',
        'permission_group_delete': host + 'subscription/permission-group/delete',

        //permission-list
        'permission_list_add': host + 'subscription/permission-list/create',
        'permission_list_edit': host + 'subscription/permission-list/update',
        'permission_list_get': host + 'subscription/permission-list',
        'permission_list_delete': host + 'subscription/permission-list/delete',

        'permission_listing_add': host + 'subscription/permission-listing/create',
        'permission_listing_edit': host + 'subscription/permission-listing/update',
        'permission_listing_get': host + 'subscription/permission-listing',
        'permission_listing_delete': host + 'subscription/permission-listing/delete',

        //feature-list
        'feature_add': host + 'subscription/feature/create',
        'feature_edit': host + 'subscription/feature/update',
        'feature_get': host + 'subscription/features',
        'feature_delete': host + 'subscription/feature/delete',

        //feature-list
        'plan_add': host + 'subscription/plan/create',
        'plan_edit': host + 'subscription/plan/update',
        'plan_get': host + 'subscription/plans',
        'plan_delete': host + 'subscription/plan/delete',

        //price-list
        'pricing_add': host + 'subscription/pricing/create',
        'pricing_edit': host + 'subscription/pricing/update',
        'pricing_get': host + 'subscription/pricing/dash',
        'pricing_get_all': host + 'subscription/pricing/all',
        'pricing_delete': host + 'subscription/pricing/delete',

        'selected_subscription_all': host + 'subscription/selected/all',
        'selected_subscription_delete': host + 'subscription/selected/delete',

        'product_source_add': host + 'source/product-source/create',
        'product_source_edit': host + 'source/product-source/update',
        'product_source_get': host + 'source/product-source',
        'product_source_delete': host + 'source/product-source/delete',
        'product_source_status_toggle': host + 'source/product-source/status/toggle',

        'product_source_brand_add': host + 'source/product-source/brand/create',
        'product_source_brand_edit': host + 'source/product-source/brand/update',
        'product_source_brand_get': host + 'source/product-source/brand',
        'product_source_brand_delete': host + 'source/product-source/brand/delete',
        'product_source_brand_status_toggle': host + 'source/product-source/brand/status/toggle',
        'product_source_direct_status_toggle': host + 'source/product-source/brand/direct-sourcing/toggle',

        'coupon_update': host + 'coupon/edit',
        'coupon_read': host + 'coupon/',
        'coupon_add': host + 'coupon/create',
        'coupon_delete': host + 'coupon/delete',
        'coupon_status_toggle': host + 'coupon/status',

        'finance_paginated':host+'affiliate/finance',
        'withdraw_paginated':host+'affiliate/withdraw',
        'withdraw_request':host+'affiliate/withdraw/request/status',


        'knowledgeBase_category_edit':host+"knowledge-base/category/update",
        'knowledgeBase_category_delete':host+"knowledge-base/category/delete",
        'knowledgeBase_category_get':host+"knowledge-base/category/list",
        'knowledgeBase_category_add':host+'knowledge-base/category/create',

        'knowledgeBase_edit':host+"knowledge-base/update",
        'knowledgeBase_delete':host+"knowledge-base/delete",
        'knowledgeBase_get':host+"knowledge-base/list",
        'knowledgeBase_getdetail':host+"knowledge-base/getdetail",
        'knowledgeBase_add':host+'knowledge-base/create',

        'FX_get':host+"subscription/fx-rate-settings",
        'FX_edit':host+'subscription/fx-rate-settings/edit',
    }
};
