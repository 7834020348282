import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    stateUpdate : 'STATE_UPDATE',
    stateDelete : 'STATE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        state: [],
        stateLoading: false,
        stateError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_STATE (state, newState) {
            state.state = newState;
        },

        STATE_LOADING (state, loading) {
            state.stateLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateState({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.stateUpdate,
                async () => {
                    let payloadData={
                        name:payload.name
                    }
                    let magic=encode(payloadData)
                    response= await axios.post(V1API.state_edit+'/'+payload.state_id,magic.encodedPayload, magic.config).then(result=>{

                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchState');

                }
            )
            return response
        },

        async deleteState({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.stateDelete,
                async () => {

                    response= await axios.delete(V1API.state_delete+'/'+payload.state_id).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    });
                    dispatch('fetchState');
                }
            )

            return response
        },

        async fetchState ({ commit },payload) {
            commit('STATE_LOADING', true);
            commit('STATE_ERROR', false);
            try{
                let result = await axios.get(V1API.state_get);
                let response=decode(result.data)
                commit('SET_STATE', response.data);

            } catch(error) {
                commit('STATE_ERROR', true);
            }
            commit('STATE_LOADING', false);
        },
        async fetchStateByCountry ({ commit },payload) {
            commit('STATE_LOADING', true);
            commit('STATE_ERROR', false);
            try{

                let result = await axios.get(V1API.state_by_country_get+"/"+payload.country_id);
                let response=decode(result.data)
                commit('SET_STATE', response.data);

            } catch(error) {
                console.log(error)
                commit('STATE_ERROR', true);
            }
            commit('STATE_LOADING', false);
        },
        async addState({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.state_add,magic.encodedPayload,magic.config).then(result=>{

                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchState')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
