import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import {decode, encode} from "@/store/dataMagic";


const keyList = {
    businessTypeUpdate : 'BUSINESS_TYPE_UPDATE',
    businessTypeDelete : 'BUSINESS_TYPE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        businessTypes: [],

        businessTypeLoading: false,
        businessTypeError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,
        ...store
    },
    mutations : {
        ...mutations,
        SET_BUSINESS_TYPE (state, businessTypes) {
            state.businessTypes = businessTypes;
        },
        BUSINESS_TYPE_LOADING (state, loading) {
            state.businessTypeLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateBusinessType({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.businessTypeUpdate,
                 async () => {
                     let magic=encode({business_type:payload.business_type})
                        response= await axios.post(V1API.businessTypes_edit+'/'+payload.business_type_id,magic.encodedPayload, magic.config).then(result=>{
                        let data=decode(result.data)

                        return data.message
                    })

                    dispatch('fetchBusinessTypes');

                }
            )
            return response
        },

        async deleteBusinessType({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.businessTypeDelete,
                async () => {

                    response= await axios.delete(V1API.businessTypes_delete+"/"+payload.business_type_id).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    });
                    dispatch('fetchBusinessTypes');
                }
            )

            return response
        },

        async fetchBusinessTypes ({ commit }) {
            commit('BUSINESS_TYPE_LOADING', true);
            commit('BUSINESS_TYPE_ERROR', false);
            try{
                let result = await axios.get(V1API.businessTypes_get);
                let response=decode(result.data)
                commit('SET_BUSINESS_TYPE', response.data);

            } catch(error) {
                commit('BUSINESS_TYPE_ERROR', true);
            }
            commit('BUSINESS_TYPE_LOADING', false);
        },
        async addBusinessType({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.businessTypes_add,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchBusinessTypes')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
