import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    dodSettingsUpdate : 'DOD_SETTINGS_UPDATE',
    dodSettingsDelete : 'DOD_SETTINGS_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        dodSettings:{},
        dodSettingsLoading: false,
        dodSettingsError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_DOD_SETTINGS (state, newDodSettings) {
            state.dodSettings = newDodSettings;
        },

        DOD_SETTINGS_LOADING (state, loading) {
            state.dodSettingsLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateDodSettings({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.dodSettingsUpdate,
                async () => {
                    let magic=encode(payload)
                    response= await axios.post(V1API.dod_settings_update,magic.encodedPayload, magic.config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchDodSettings');

                }
            )
            return response
        },
        async fetchDodSettings ({ commit }) {
            commit('DOD_SETTINGS_LOADING', true);
            commit('DOD_SETTINGS_ERROR', false);
            try{
                let result = await axios.get(V1API.dod_settings_read);
                let response=decode(result.data)
                commit('SET_DOD_SETTINGS', response.data);

            } catch(error) {
                commit('DOD_SETTINGS_ERROR', true);
            }
            commit('DOD_SETTINGS_LOADING', false);
        },

    },
    getters: {}
}
