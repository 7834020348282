import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import {decode, encode} from "@/store/dataMagic";


const keyList = {
    businessUpdate : 'BUSINESS_UPDATE',
    businessDelete : 'BUSINESS_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        businesses: [],
        businessSimple: [],

        businessLoading: false,
        businessError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_BUSINESS (state, business) {
            state.businesses= business;
        },
        SET_BUSINESS_SIMPLE (state, business) {
            state.businessSimple= business;
        },

        BUSINESS_LOADING (state, loading) {
            state.businessLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateBusiness({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.businessUpdate,
                async () => {
                    let magic=encode(payload)
                    response= await axios.patch(V1API.business_edit,magic.encodedPayload, magic.config).then(result=>{
                        let data=decode(result.data)
                        console.log(data)
                        return data
                    })

                }
            )
            return response
        },

        async toggleBusinessStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.businessUpdate,
                async () => {
                    response= await axios.get(V1API.business_status_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchBusiness');

                }
            )
            return response
        },
        async toggleBusinessCommissionStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.businessUpdate,
                async () => {
                    response= await axios.get(V1API.business_commission_status_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchBusiness');

                }
            )
            return response
        },
        async toggleBusinessFeatureStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.businessUpdate,
                async () => {
                    response= await axios.get(V1API.business_feature_status_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchBusiness');

                }
            )
            return response
        },

        async fetchBusiness ({ commit },payload) {
            commit('BUSINESS_LOADING', true);
            commit('BUSINESS_ERROR', false);
            try{

                let result = await axios.get(V1API.business_get);
                let response=decode(result.data)
                commit('SET_BUSINESS', response.data);

            } catch(error) {
                commit('BUSINESS_ERROR', true);
            }
            commit('BUSINESS_LOADING', false);
        },
        async fetchBusinessSimple ({ commit },payload) {
            commit('BUSINESS_LOADING', true);
            commit('BUSINESS_ERROR', false);
            try{
                let magic=encode(payload)
                let result = await axios.get(V1API.business_simple, magic.config);
                let response=decode(result.data)
                commit('SET_BUSINESS_SIMPLE', response.data);

            } catch(error) {
                commit('BUSINESS_ERROR', true);
            }
            commit('BUSINESS_LOADING', false);
        },
        async deleteBusiness({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.businessDelete,
                async () => {
                    response= await axios.get(V1API.business_delete+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    });
                    dispatch('fetchBusiness');
                }
            )

            return response
        },

        async addBusiness({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.business_add,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    console.log(data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
