import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import {decode, encode} from "@/store/dataMagic";


const keyList = {
    customerUpdate : 'CUSTOMER_UPDATE',
    customerDelete : 'CUSTOMER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        customers: [],

        customerLoading: false,
        customerError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_CUSTOMER (state, customer) {
            state.customers= customer;
        },

        CUSTOMER_LOADING (state, loading) {
            state.customerLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async fetchCustomer ({ commit },payload) {
            commit('CUSTOMER_LOADING', true);
            commit('CUSTOMER_ERROR', false);
            try{

                let result = await axios.get(V1API.customer_get);
                let response=decode(result.data)
                commit('SET_CUSTOMER', response.data);

            } catch(error) {
                commit('CUSTOMER_ERROR', true);
            }
            commit('CUSTOMER_LOADING', false);
        },


    },
    getters: {}
}
