import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    imageUpdate : 'IMAGE_UPDATE',
    imageDelete : 'IMAGE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        image: [],
        imageLoading: false,
        imageError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,
        resId:null,

        ...store
    },
    mutations : {
        ...mutations,

        IMAGE_LOADING (state, loading) {
            state.imageLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async addImage({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let data={
                    image:undefined
                }

                data.image=payload;
                let response = await axios.post(V1API.image_upload,data).then(result=>{
                    return result.data
                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteImage({commit,dispatch}, payload) {
            try{

                let response = await axios.delete(V1API.image_delete+payload+"/").then(result=>{
                    return result.data
                });

                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
