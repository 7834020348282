import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";
import dummyResponse from "@/store/order/dummyResponse";

const keyList = {
    orderUpdate: 'ORDER_UPDATE',
    orderDelete: 'ORDER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        orders: [],
        orderLoading: false,
        totalCount:0,

        orderError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,
        SET_ORDER(state, newOrder) {
            state.orders = newOrder;
        },
        SET_TOTAL_ORDER(state,count){
            state.totalCount=count
        },
        ORDER_LOADING(state, loading) {
            state.orderLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateOrder({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.orderUpdate,
                async () => {

                    response = await axios.post(V1API.order_status_update + '/' + payload.order_id + '/' + payload.new_status).then(result => {
                        let data = decode(result.data)
                        return data
                    })
                    dispatch('fetchOrder');
                }
            )
            return response
        },

        async fetchOrder({commit},payload) {
            commit('ORDER_LOADING', true);
            commit('ORDER_ERROR', false);
            try {
                let result = await axios.get(V1API.order_all_paginated+ "?count=" + payload.count +"&page="+payload.page);
                let response = decode(result.data)
                commit('SET_ORDER', response.results);
                commit('SET_TOTAL_ORDER', response.count);
                // commit('SET_ORDER', dummyResponse.order_list);

            } catch (error) {
                commit('ORDER_ERROR', true);
            }
            commit('ORDER_LOADING', false);
        },

        async fetchOrderByStatus({commit}, payload) {
            commit('ORDER_LOADING', true);
            commit('ORDER_ERROR', false);
            try {
                let result = await axios.get(V1API.order_by_status_paginated + '/' + payload.id+ "?count=" + payload.count +"&page="+payload.page);
                let response = decode(result.data)
                commit('SET_ORDER', response.results);
                commit('SET_TOTAL_ORDER', response.count);
            } catch (error) {
                commit('ORDER_ERROR', true);
            }
            commit('ORDER_LOADING', false);
        },

        // async addOrder({commit,dispatch}, payload) {
        //     commit('SUBMIT_LOADING', true);
        //     commit('SUBMIT_ERROR', false);
        //     try{
        //         let magic=encode(payload)
        //         let response = await axios.post(V1API.order_add,magic.encodedPayload,magic.config).then(result=>{
        //             let data=decode(result.data)
        //             return data
        //         });
        //         commit('SUBMIT_SUCCESS', true);
        //         dispatch ('fetchOrder')
        //         return response
        //     }catch (e) {
        //         commit('SUBMIT_ERROR', true);
        //     }finally {
        //         commit('SUBMIT_LOADING', false);
        //     }
        // },

    },
    getters: {}
}
