import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    knowledgeBaseUpdate: 'KNOWLEDGE_BASE_UPDATE',
    knowledgeBaseDelete: 'KNOWLEDGE_BASE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        knowledgeBase: [],
        knowledgeBaseLoading: false,
        knowledgeBaseError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations: {
        ...mutations,
        SET_KNOWLEDGE_BASE(state, knowledgeBase) {
            state.knowledgeBase = knowledgeBase;
        },

        KNOWLEDGE_BASE_LOADING(state, loading) {
            state.knowledgeBaseLoading = loading;
        },

        KNOWLEDGE_BASE_ERROR(state, hasError) {
            state.knowledgeBaseError = hasError;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateKnowledgeBase({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.knowledgeBaseUpdate,
                async () => {

                    let magic = encode(payload)
                    response = await axios.patch(V1API.knowledgeBase_edit + "/" + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchKnowledgeBase');

                }
            )
            return response
        },


        async deleteKnowledgeBase({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.knowledgeBaseDelete,
                async () => {
                    response = await axios.delete(V1API.knowledgeBase_delete + '/' + payload).then(result => {
                        let data = decode(result.data)

                        return data.message

                    });
                    dispatch('fetchKnowledgeBase');
                }
            )

            return response
        },

        async fetchKnowledgeBase({commit}) {
            commit('KNOWLEDGE_BASE_LOADING', true);
            commit('KNOWLEDGE_BASE_ERROR', false);
            try {

                let result = await axios.get(V1API.knowledgeBase_get);
                let response = decode(result.data)
                console.log("knowledgeBase")

                commit('SET_KNOWLEDGE_BASE', response.data);

            } catch (error) {

                commit('KNOWLEDGE_BASE_ERROR', true);

            }
            commit('KNOWLEDGE_BASE_LOADING', false);
        },
        async fetchKnowledgeBaseBySlug({commit},payload) {
            commit('KNOWLEDGE_BASE_LOADING', true);
            commit('KNOWLEDGE_BASE_ERROR', false);
            try {

                let result = await axios.get(V1API.knowledgeBase_getdetail+'/'+payload);
                let response = decode(result.data)
                console.log("Details")


                commit('SET_KNOWLEDGE_BASE', response.data);
                return response.data

            } catch (error) {

                commit('KNOWLEDGE_BASE_ERROR', true);

            }
            commit('KNOWLEDGE_BASE_LOADING', false);
        },

        async addKnowledgeBase({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let magic = encode(payload)
                let response = await axios.post(V1API.knowledgeBase_add, magic.encodedPayload, magic.config).then(result => {
                    let data = decode(result.data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchKnowledgeBase')
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
