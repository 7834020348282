export default [

    {
        path: '/dashboard/knowledge-base-management/knowledge-base-category',
        name: 'knowledge-base-list',
        component: () => import('@/views/pages/nextcart/knowledgeBaseManagement/KnowledgeCategoryTable.vue'),
        meta:{
            codename:'view_knowledgebaseCategory'
        }

    },
    {
        path: '/dashboard/knowledge-base-management/knowledge-base-add',
        name: 'knowledge-base-add',
        component: () => import('@/views/pages/nextcart/knowledgeBaseManagement/KnowledgeBaseModuleAdd.vue'),
        meta:{
            codename:'add_knowledgebase'
        }

    },
    {
        path: '/dashboard/knowledge-base-management/knowledge-base-modules',
        name: 'knowledge-base',
        component: () => import('@/views/pages/nextcart/knowledgeBaseManagement/KnowledgeModuleTable.vue'),
        meta:{
            codename:'view_knowledgebase'
        }

    }, {
        path: '/dashboard/knowledge-base-management/knowledge-base-modules/edit/:id',
        name: 'knowledge-base-edit',
        component: () => import('@/views/pages/nextcart/knowledgeBaseManagement/KnowledgeBaseModuleEdit.vue'),
        meta:{
            codename:'edit_knowledgebase'
        }

    },
    // {
    //     path: '/dashboard/theme-management/themes',
    //     name: 'theme',
    //     component: () => import('@/views/pages/nextcart/themeManagement/ThemeTable.vue'),
    //     meta:{
    //         codename:'view_theme'
    //     }
    // },
    // {
    //     path: '/dashboard/database/state',
    //     name: 'database-state',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/StateTable'),
    //     meta:{
    //         codename:'view_state'
    //     }
    // },
    // {
    //     path: '/dashboard/database/country',
    //     name: 'database-country',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/CountryTable'),
    //     meta:{
    //         codename:'view_country'
    //
    //     }
    //
    // }
]
