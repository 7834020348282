import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    withdrawalUpdate: 'WITHDRAWAL_UPDATE',
    withdrawalDelete: 'WITHDRAWAL_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        payout_details: { },
        withdrawal: [],
        withdrawalLoading: false,
        totalCount: 0,
        currentBalance:0,
        withdrawalError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,
        SET_WITHDRAWAL(state, newFinance) {
            state.withdrawal = newFinance;
        },
        SET_PAYOUT(state, newFinance) {
            state.payout_details = newFinance;
        },
        SET_TOTAL(state, count) {
            state.totalCount = count
        },
        WITHDRAWAL_LOADING(state, loading) {
            state.withdrawalLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },
        SET_BALANCE(state,newCurrentBalance){
            state.currentBalance=newCurrentBalance
        },
        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async fetchWithdrawLog({commit}, payload) {
            commit('WITHDRAWAL_LOADING', true);
            commit('WITHDRAWAL_ERROR', false);
            try {
                let result = await axios.get(V1API.withdraw_paginated+'/'+payload.user + "?count=" + payload.count + "&page=" + payload.page);
                let response = decode(result.data)
                commit('SET_WITHDRAWAL', response.results);
                commit('SET_TOTAL', response.count);
            } catch (error) {
                commit('WITHDRAWAL_ERROR', true);
            }
            commit('WITHDRAWAL_LOADING', false);
        },

        async updateWithdrawReq({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let magic = encode(payload)
                let response = await axios.get(V1API.withdraw_request + '/' + payload.id + '/' + payload.status).then(result => {
                    let data = decode(result.data)
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchWithdrawLog', {user:payload.user, count: 10, page: 1})
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },


    },
    getters: {}
}
