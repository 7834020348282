import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    productSourceUpdate: 'PRODUCT_SOURCE_UPDATE',
    productSourceDelete: 'PRODUCT_SOURCE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        productSourceDistributor: [],
        productSourceJson: [],
        productSourceLoading: false,
        productSourceError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations: {
        ...mutations,
        SET_PRODUCT_SOURCE(state, productSource) {
            state.productSourceDistributor = productSource;
        },
        SET_PRODUCT_SOURCE_JSON(state, productSource) {
            state.productSourceJson = productSource;
        },

        PRODUCT_SOURCE_LOADING(state, loading) {
            state.productSourceLoading = loading;
        },

        PRODUCT_SOURCE_ERROR(state, hasError) {
            state.productSourceError = hasError;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateProductSource({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.productSourceUpdate,
                async () => {

                    let magic = encode(payload)
                    response = await axios.patch(V1API.product_source_edit + "/" + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data.message
                    })

                    dispatch('fetchProductSource');

                }
            )
            return response
        },
        async toggleSourceStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.businessUpdate,
                async () => {
                    response= await axios.get(V1API.product_source_status_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchProductSource');

                }
            )
            return response
        },


        async deleteProductSource({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.productSourceDelete,
                async () => {
                    response = await axios.delete(V1API.product_source_delete + '/' + payload).then(result => {
                        let data = decode(result.data)

                        return data.message

                    });
                    dispatch('fetchProductSource');
                }
            )

            return response
        },

        async fetchProductSource({commit}) {
            commit('PRODUCT_SOURCE_LOADING', true);
            commit('PRODUCT_SOURCE_ERROR', false);
            try {

                let result = await axios.get(V1API.product_source_get);
                let response = decode(result.data)


                commit('SET_PRODUCT_SOURCE', response.data);

            } catch (error) {

                commit('PRODUCT_SOURCE_ERROR', true);

            }
            commit('PRODUCT_SOURCE_LOADING', false);
        },

        async addProductSource({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let magic = encode(payload)
                let response = await axios.post(V1API.product_source_add, magic.encodedPayload, magic.config).then(result => {
                    let data = decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchProductSource')
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
