import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import {decode, encode} from "@/store/dataMagic";


const keyList = {
    affiliateOnboardUpdate : 'AFFILIATE_ONBOARD_UPDATE',
    affiliateOnboardDelete : 'AFFILIATE_ONBOARD_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        submitLoading: false,
        submitError: false,
        submitSuccess: false,
        affiliates: [],
        affiliateSimple: [],

        ...store
    },
    mutations : {
        ...mutations,
        SET_AFFILIATE (state, affiliate) {
            state.affiliates= affiliate;
        },
        SET_AFFILIATE_SIMPLE (state, affiliate) {
            state.affiliateSimple= affiliate;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async fetchAffiliateList ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{

                let result = await axios.get(V1API.affiliate_list);
                let response=decode(result.data)
                commit('SET_AFFILIATE', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },

        async fetchAffiliateListSimple ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{

                let result = await axios.get(V1API.affiliate_list_simple);
                let response=decode(result.data)
                commit('SET_AFFILIATE_SIMPLE', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },
        async addUser({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                console.log(payload)
                let response = await axios.post(V1API.affiliate_create,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async addAffiliate({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                console.log(payload)
                let response = await axios.post(V1API.affiliate_data_create,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
