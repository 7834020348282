import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    productUpdate : 'PRODUCT_UPDATE',
    productDelete : 'PRODUCT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        products: [],
        productsSimple: [],
        productLoading: false,
        productError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_PRODUCT(state, newProduct) {
            state.products = newProduct;
        },
       SET_PRODUCT_SIMPLE(state, newProduct) {
            state.productsSimple = newProduct
       },

        PRODUCT_LOADING (state, loading) {
            state.productLoading = loading
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async fetchProductByBusiness({ commit },payload) {
            commit('PRODUCT_LOADING', true);
            commit('PRODUCT_ERROR', false);
            try{
                let result = await axios.get(V1API.product_by_business+'/'+payload.business_id);
                let response=decode(result.data)
                commit('SET_PRODUCT', response.data);

            } catch(error) {
                commit('PRODUCT_ERROR', true);
            }
            commit('PRODUCT_LOADING', false);
        },
        async fetchProductByBusinessSimple({ commit },payload) {
            commit('PRODUCT_LOADING', true);
            commit('PRODUCT_ERROR', false);
            try{
                let result = await axios.get(V1API.product_by_business_simple+'/'+payload.business_id);
                let response=decode(result.data)
                commit('SET_PRODUCT_SIMPLE', response.data);

            } catch(error) {
                commit('PRODUCT_ERROR', true);
            }
            commit('PRODUCT_LOADING', false);
        },
        async toggleProductStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.DodProductsUpdate,
                async () => {
                    response= await axios.get(V1API.product_status_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchProductByBusiness',{business_id:payload.business_id});

                }
            )
            return response
        },

        async toggleAdvanceStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.DodProductsUpdate,
                async () => {
                    response= await axios.get(V1API.product_advance_status_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchProducts');

                }
            )
            return response
        },

        async fetchProducts({ commit },payload) {
            commit('PRODUCT_LOADING', true);
            commit('PRODUCT_ERROR', false);
            try{
                let result = await axios.get(V1API.product_all);
                let response=decode(result.data)
                commit('SET_PRODUCT', response.data);

            } catch(error) {
                commit('PRODUCT_ERROR', true);
            }
            commit('PRODUCT_LOADING', false);
        },

        async fetchProductsSimple({ commit }) {
            commit('PRODUCT_LOADING', true);
            commit('PRODUCT_ERROR', false);
            try{
                let result = await axios.get(V1API.product_simple);
                let response=decode(result.data)
                commit('SET_PRODUCT_SIMPLE', response.data);

            } catch(error) {
                commit('PRODUCT_ERROR', true);
            }
            commit('PRODUCT_LOADING', false);
        },



    },
    getters: {}
}
