import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import {decode, encode} from "@/store/dataMagic";


const keyList = {
    reservedSlugUpdate : 'RESERVED_SLUG_UPDATE',
    reservedSlugDelete : 'RESERVED_SLUG_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        reservedSlugs: [],

        reservedSlugLoading: false,
        reservedSlugError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,
        ...store
    },
    mutations : {
        ...mutations,
        SET_RESERVED_SLUG (state, reservedSlugs) {
            state.reservedSlugs = reservedSlugs;
        },
        RESERVED_SLUG_LOADING (state, loading) {
            state.reservedSlugLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateReservedSlug({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.reservedSlugUpdate,
                 async () => {
                     let magic=encode({slug:payload.slug})
                        response= await axios.post(V1API.reservedSlug_edit+'/'+payload.slug_id,magic.encodedPayload, magic.config).then(result=>{
                        let data=decode(result.data)

                        return data.message
                    })

                    dispatch('fetchReservedSlugs');

                }
            )
            return response
        },

        async deleteReservedSlug({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.reservedSlugDelete,
                async () => {

                    response= await axios.delete(V1API.reservedSlug_delete+"/"+payload.slug_id).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    });
                    dispatch('fetchReservedSlugs');
                }
            )

            return response
        },

        async fetchReservedSlugs ({ commit }) {
            commit('RESERVED_SLUG_LOADING', true);
            commit('RESERVED_SLUG_ERROR', false);
            try{
                let result = await axios.get(V1API.reservedSlug_get);
                let response=decode(result.data)
                commit('SET_RESERVED_SLUG', response.data);

            } catch(error) {
                commit('RESERVED_SLUG_ERROR', true);
            }
            commit('RESERVED_SLUG_LOADING', false);
        },
        async addReservedSlug({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.reservedSlug_add,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchReservedSlugs')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
