import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    themeTypeUpdate : 'THEME_TYPE_UPDATE',
    themeTypeDelete : 'THEME_TYPE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        themeType: [],
        themeTypeLoading: false,
        themeTypeError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_THEME_TYPE (state, themeType) {
            state.themeType = themeType;
        },

        THEME_TYPE_LOADING (state, loading) {
            state.themeTypeLoading = loading;
        },

        THEME_TYPE_ERROR (state, hasError) {
            state.themeTypeError = hasError;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateThemeType({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.themeTypeUpdate,
                async () => {
                    let payloadData={
                        'name':payload.name
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.theme_type_edit+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchThemeType');

                }
            )
            return response
        },



        async deleteThemeType({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.themeTypeDelete,
                async () => {
                    response = await axios.delete(V1API.theme_type_delete+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchThemeType');
                }
            )

            return response
        },

        async fetchThemeType ({ commit }) {
            commit('THEME_TYPE_LOADING', true);
            commit('THEME_TYPE_ERROR', false);
            try{

                let result = await axios.get(V1API.theme_type_get);
                let response=decode(result.data)
                console.log(response.data)

                commit('SET_THEME_TYPE', response.data);

            }catch(error) {

                commit('THEME_TYPE_ERROR', true);

            }
            commit('THEME_TYPE_LOADING', false);
        },
        async addThemeType({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.theme_type_add,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchThemeType')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
