export default [
    // {
    //     path: '/dashboard/affiliate-management/onboarded-affiliates',
    //     name: 'business-list',
    //     component: () => import('@/views/pages/nextcart/businessManagement/BusinessList.vue'),
    //     meta:{
    //         codename:'view_businesscore'
    //     }
    //
    // },
    {
        path: '/dashboard/affiliate-management/affiliate-onboarding',
        name: 'affiliate-add',
        component: () => import('@/views/pages/nextcart/affiliateManagement/AffiliateCreate.vue'),
        meta:{
            codename:'view_affiliateadd'
        }

    },
    {
        path: '/dashboard/affiliate-management/affiliate-list',
        name: 'affiliate-list',
        component: () => import('@/views/pages/nextcart/affiliateManagement/AffiliateList.vue'),
        meta:{
            codename:'view_affiliatelist'
        }

    },
    {
        path: '/dashboard/affiliate-finance',
        name: 'affiliate-finance',
        component: () => import('@/views/pages/nextcart/affiliateManagement/AffiliateFinance.vue'),
        meta:{
            codename:'view_affiliatefinance'
        }

    },  {
        path: '/dashboard/affiliate-withdraw-requests',
        name: 'affiliate-withdraw',
        component: () => import('@/views/pages/nextcart/affiliateManagement/AffiliateWithdrawal.vue'),
        meta:{
            codename:'view_affiliatewithdraw'
        }

    },
    // {
    //     path: '/dashboard/theme-management/themes',
    //     name: 'theme',
    //     component: () => import('@/views/pages/nextcart/themeManagement/ThemeTable.vue'),
    //     meta:{
    //         codename:'view_theme'
    //     }
    // },
    // {
    //     path: '/dashboard/database/state',
    //     name: 'database-state',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/StateTable'),
    //     meta:{
    //         codename:'view_state'
    //     }
    // },
    // {
    //     path: '/dashboard/database/country',
    //     name: 'database-country',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/CountryTable'),
    //     meta:{
    //         codename:'view_country'
    //
    //     }
    //
    // }
]
