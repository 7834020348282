import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    deploymentUrlUpdate : 'DEPLOYMENT_URL_UPDATE',
    deploymentUrlDelete : 'DEPLOYMENT_URL_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        deploymentUrl: [],
        deploymentUrlLoading: false,
        deploymentUrlError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_DEPLOYMENT_URL (state, deploymentUrl) {
            state.deploymentUrl = deploymentUrl;
        },

        DEPLOYMENT_URL_LOADING (state, loading) {
            state.deploymentUrlLoading = loading;
        },

        DEPLOYMENT_URL_ERROR (state, hasError) {
            state.deploymentUrlError = hasError;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateDeploymentUrl({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.deploymentUrlUpdate,
                async () => {

                    let magic=encode(payload)
                    response = await axios.patch(V1API.deployment_url_edit+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchDeploymentUrl');

                }
            )
            return response
        },



        async deleteDeploymentUrl({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.deploymentUrlDelete,
                async () => {
                    response = await axios.delete(V1API.deploymentUrl_delete+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchDeploymentUrl');
                }
            )

            return response
        },

        async fetchDeploymentUrl({ commit }) {
            commit('DEPLOYMENT_URL_LOADING', true);
            commit('DEPLOYMENT_URL_ERROR', false);
            try{

                let result = await axios.get(V1API.deployment_url_get);
                let response=decode(result.data)
                console.log(response.data)

                commit('SET_DEPLOYMENT_URL', response.data);

            }catch(error) {

                commit('DEPLOYMENT_URL_ERROR', true);

            }
            commit('DEPLOYMENT_URL_LOADING', false);
        },
        async addDeploymentUrl({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.deployment_url_add,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchDeploymentUrl')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
