import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    serverListUpdate : 'SERVER_LIST_UPDATE',
    serverListDelete : 'SERVER_LIST_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        serverList: [],
        serverListLoading: false,
        serverListError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_SERVER_LIST (state, serverList) {
            state.serverList = serverList;
        },

        SERVER_LIST_LOADING (state, loading) {
            state.serverListLoading = loading;
        },

        SERVER_LIST_ERROR (state, hasError) {
            state.serverListError = hasError;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateServerList({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.serverListUpdate,
                async () => {

                    let payloadData={
                        'url':payload.url,
                        'ip_address':payload.ip_address,
                        'root_user':payload.root_user,
                        'root_password':payload.root_password,
                    }

                    let magic=encode(payloadData)

                    response = await axios.patch(V1API.server_list_edit+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchServerList');

                }
            )
            return response
        },



        async deleteServerList({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.serverListDelete,
                async () => {
                    response = await axios.delete(V1API.server_list_delete+'/'+payload.id).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchServerList');
                }
            )

            return response
        },

        async fetchServerList({ commit }) {
            commit('SERVER_LIST_LOADING', true);
            commit('SERVER_LIST_ERROR', false);
            try{

                let result = await axios.get(V1API.server_list_get);
                let response=decode(result.data)
                // console.log(response.data)

                commit('SET_SERVER_LIST', response.data);

            }catch(error) {

                commit('SERVER_LIST_ERROR', true);

            }
            commit('SERVER_LIST_LOADING', false);
        },
        async addServerList({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.server_list_add,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchServerList')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
