import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    siteSettingsUpdate: 'SITE_SETTINGS_UPDATE',
    siteSettingsDelete: 'SITE_SETTINGS_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        siteSettings: {},
        seoSettings: {},
        googleSettings: {},
        facebookSettings: {},
        maintenanceSettings: {},
        priceUpdateSettings: false,
        timeLimitSettings: {},

        siteFeatures: [],
        siteSettingsLoading: false,
        siteSettingsError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,
        SET_SITE_SETTINGS(state, newSiteSettings) {
            state.siteSettings = newSiteSettings;
        },
        SET_SEO_SETTINGS(state, newSeoSettings) {
            state.seoSettings = newSeoSettings;
        },
        SET_GOOGLE_SETTINGS(state, newGoogleSettings) {
            state.googleSettings = newGoogleSettings;
        },
        SET_FACEBOOK_SETTINGS(state, newFacebookSettings) {
            state.facebookSettings = newFacebookSettings;
        },
        SET_MAINTENANCE_SETTINGS(state, newMaintenanceSettings) {
            state.maintenanceSettings = newMaintenanceSettings
        },
        SET_PRICE_UPDATE_SETTINGS(state, newPriceUpdateSettings) {
            state.priceUpdateSettings = newPriceUpdateSettings
        },
        SET_TIME_LIMIT_SETTINGS(state, newTimeLimitSettings) {
            state.timeLimitSettings = newTimeLimitSettings
        },

        SET_SITE_FEATURES(state, newSiteFeatures) {
            state.siteFeatures = newSiteFeatures;
        },

        SITE_SETTINGS_LOADING(state, loading) {
            state.siteSettingsLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        //site Settings
        async updateSiteSettings({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.siteSettingsUpdate,
                async () => {
                    let magic = encode(payload)
                    response = await axios.post(V1API.site_settings_update, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchSiteSettings');

                }
            )
            return response
        },
        async fetchSiteSettings({commit}, payload) {
            commit('SITE_SETTINGS_LOADING', true);
            commit('SITE_SETTINGS_ERROR', false);
            try {
                let result = await axios.get(V1API.site_settings_read);
                let response = decode(result.data)
                commit('SET_SITE_SETTINGS', response.data);

            } catch (error) {
                commit('SITE_SETTINGS_ERROR', true);
            }
            commit('SITE_SETTINGS_LOADING', false);
        },

        //seo Settings
        async updateSeoSettings({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.siteSettingsUpdate,
                async () => {
                    let magic = encode(payload)
                    response = await axios.post(V1API.seo_settings_update, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchSeoSettings');

                }
            )
            return response
        },
        async fetchSeoSettings({commit}, payload) {
            commit('SITE_SETTINGS_LOADING', true);
            commit('SITE_SETTINGS_ERROR', false);
            try {
                let result = await axios.get(V1API.seo_settings_read);
                let response = decode(result.data)
                console.log(response)
                commit('SET_SEO_SETTINGS', response.data);

            } catch (error) {
                commit('SITE_SETTINGS_ERROR', true);
            }
            commit('SITE_SETTINGS_LOADING', false);
        },

        //Maintenance Settings toggle
        async updateMaintenanceSettings({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.siteSettingsUpdate,
                async () => {
                    response = await axios.post(V1API.maintenance_mode_toggle).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchMaintenanceSettings');

                }
            )
            return response
        },

        async fetchMaintenanceSettings({commit}, payload) {
            commit('SITE_SETTINGS_LOADING', true);
            commit('SITE_SETTINGS_ERROR', false);
            try {
                let result = await axios.get(V1API.maintenance_mode);
                let response = decode(result.data)
                console.log(response)
                commit('SET_MAINTENANCE_SETTINGS', response.data);

            } catch (error) {
                commit('SITE_SETTINGS_ERROR', true);
            }
            commit('SITE_SETTINGS_LOADING', false);
        },
        //Time Limit


        async updateTimeLimit({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.siteSettingsUpdate,
                async () => {
                    let magic = encode(payload)
                    response = await axios.post(V1API.time_limit_update, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchTimeLimit');

                }
            )
            return response
        },
        async fetchTimeLimit({commit}, payload) {
            commit('SITE_SETTINGS_LOADING', true);
            commit('SITE_SETTINGS_ERROR', false);
            try {
                let result = await axios.get(V1API.time_limit_read);
                let response = decode(result.data)
                console.log(response)
                commit('SET_TIME_LIMIT_SETTINGS', response.data);

            } catch (error) {
                commit('SITE_SETTINGS_ERROR', true);
            }
            commit('SITE_SETTINGS_LOADING', false);
        },


        //price update toggle

        async updatePriceSettings({commit, dispatch}) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.siteSettingsUpdate,
                async () => {
                    response = await axios.get(V1API.can_update_price_toggle).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchPriceSettings');

                }
            )
            return response
        },
        async fetchPriceSettings({commit}, payload) {
            commit('SITE_SETTINGS_LOADING', true);
            commit('SITE_SETTINGS_ERROR', false);
            try {
                let result = await axios.get(V1API.can_update_price_read);
                let response = decode(result.data)
                commit('SET_PRICE_UPDATE_SETTINGS', response.data);

            } catch (error) {
                commit('SITE_SETTINGS_ERROR', true);
            }
            commit('SITE_SETTINGS_LOADING', false);
        },

        //google Settings
        async updateGoogleSettings({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.siteSettingsUpdate,
                async () => {
                    let magic = encode(payload)
                    response = await axios.post(V1API.google_settings_update, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchGoogleSettings');

                }
            )
            return response
        },
        async fetchGoogleSettings({commit}, payload) {
            commit('SITE_SETTINGS_LOADING', true);
            commit('SITE_SETTINGS_ERROR', false);
            try {
                let result = await axios.get(V1API.google_settings_read);
                let response = decode(result.data)
                console.log(response)
                commit('SET_PRICE_UPDATE_SETTINGS', response.data);

            } catch (error) {
                commit('SITE_SETTINGS_ERROR', true);
            }
            commit('SITE_SETTINGS_LOADING', false);
        },

        //google Settings
        async updateFacebookSettings({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.siteSettingsUpdate,
                async () => {
                    let magic = encode(payload)
                    response = await axios.post(V1API.facebook_settings_update, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchFacebookSettings');

                }
            )
            return response
        },
        async fetchFacebookSettings({commit}, payload) {
            commit('SITE_SETTINGS_LOADING', true);
            commit('SITE_SETTINGS_ERROR', false);
            try {
                let result = await axios.get(V1API.facebook_settings_read);
                let response = decode(result.data)
                console.log(response)
                commit('SET_FACEBOOK_SETTINGS', response.data);

            } catch (error) {
                commit('SITE_SETTINGS_ERROR', true);
            }
            commit('SITE_SETTINGS_LOADING', false);
        },


        async updateSiteFeatures({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.siteSettingsUpdate,
                async () => {
                    let magic = encode(payload.payload)
                    response = await axios.post(V1API.site_feature_update + '/' + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchSiteFeatures');

                }
            )
            return response
        },
        async fetchSiteFeatures({commit}, payload) {
            commit('SITE_SETTINGS_LOADING', true);
            commit('SITE_SETTINGS_ERROR', false);
            try {
                let result = await axios.get(V1API.site_feature_read);
                let response = decode(result.data)
                commit('SET_SITE_FEATURES', response.data);

            } catch (error) {
                commit('SITE_SETTINGS_ERROR', true);
            }
            commit('SITE_SETTINGS_LOADING', false);
        },


    },
    getters: {}
}
