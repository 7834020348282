import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    FXUpdate : 'FX_UPDATE',
    FXDelete : 'FX_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        FX:0.0,
        FXLoading: false,
        FXError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_FX (state, FX) {
            state.FX = FX;
        },

        FX_LOADING (state, loading) {
            state.FXLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateFX({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.FXUpdate,
                async () => {
                    let payloadData={
                        rate: payload.rate
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.FX_edit,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchFX');

                }
            )
            console.log(response)
            return response
        },




        async fetchFX ({ commit },payload) {
            commit('FX_LOADING', true);
            commit('FX_ERROR', false);
            try{

                let result = await axios.get(V1API.FX_get);
                let response=decode(result.data)
                commit('SET_FX', response.data);
                return response.data
            }catch(error) {

                commit('FX_ERROR', true);

            }
            commit('FX_LOADING', false);
        },


    },
    getters: {}
}
