import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    permissionListUpdate: 'PERMISSION_LIST_UPDATE',
    permissionListDelete: 'PERMISSION_LIST_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        permissionList: [],
        permissionListLoading: false,
        permissionListError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations: {
        ...mutations,
        SET_PERMISSION_LIST(state, permissionList) {
            state.permissionList = permissionList;
        },

        PERMISSION_LIST_LOADING(state, loading) {
            state.permissionListLoading = loading;
        },

        PERMISSION_LIST_ERROR(state, hasError) {
            state.permissionListError = hasError;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updatePermissionList({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.permissionListUpdate,
                async () => {

                    let magic = encode(payload)
                    response = await axios.patch(V1API.permission_list_edit + "/" + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data.message
                    })

                    dispatch('fetchPermissionList');

                }
            )
            return response
        },


        async deletePermissionList({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.permissionListDelete,
                async () => {
                    response = await axios.delete(V1API.permission_list_delete + '/' + payload).then(result => {
                        let data = decode(result.data)

                        return data.message

                    });
                    dispatch('fetchPermissionList');
                }
            )

            return response
        },

        async fetchPermissionList({commit}) {
            commit('PERMISSION_LIST_LOADING', true);
            commit('PERMISSION_LIST_ERROR', false);
            try {

                let result = await axios.get(V1API.permission_list_get);
                let response = decode(result.data)


                commit('SET_PERMISSION_LIST', response.data);

            } catch (error) {

                commit('PERMISSION_LIST_ERROR', true);

            }
            commit('PERMISSION_LIST_LOADING', false);
        },
        async addPermissionList({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let magic = encode(payload)
                let response = await axios.post(V1API.permission_list_add, magic.encodedPayload, magic.config).then(result => {
                    let data = decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchPermissionList')
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
