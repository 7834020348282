import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    sliderImageUpdate : 'SLIDER_IMAGE_UPDATE',
    sliderImageDelete : 'SLIDER_IMAGE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        sliderImage: [],
        sliderImageLoading: false,
        sliderImageError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_SLIDER_IMAGE (state, newSliderImage) {
            state.sliderImage = newSliderImage;
        },

        SLIDER_IMAGE_LOADING (state, loading) {
            state.sliderImageLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateSliderImage({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.sliderImageUpdate,
                async () => {
                    let magic=encode(payload)
                    response= await axios.post(V1API.product_category_update,magic.encodedPayload, magic.config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchSliderImage');

                }
            )
            return response
        },

        async deleteSliderImage({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.sliderImageDelete,
                async () => {
                    response= await axios.delete(V1API.slider_image_delete+'/'+payload.image_id).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchSliderImage');
                }
            )

            return response
        },
        async toggleImageStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.sliderImageUpdate,
                async () => {
                    // response= await axios.post(V1API.product_feature_toggle+'/'+payload.id).then(result=>{
                    //     let data=decode(result.data)
                    //     return data.message
                    // })

                    dispatch('fetchProduct');

                }
            )
            return response
        },


        async fetchSliderImage ({ commit },payload) {
            commit('SLIDER_IMAGE_LOADING', true);
            commit('SLIDER_IMAGE_ERROR', false);
            try{
                let result = await axios.get(V1API.slider_image_read);
                let response=decode(result.data)
                console.log(response)
                commit('SET_SLIDER_IMAGE', response.data);

            } catch(error) {
                commit('SLIDER_IMAGE_ERROR', true);
            }
            commit('SLIDER_IMAGE_LOADING', false);
        },

        async addSliderImage({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.slider_image_create,magic.encodedPayload,magic.config).then(result=>{

                    let data=decode(result.data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchSliderImage')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
