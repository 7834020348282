import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";
import dummyResponse from "@/store/cloudX/invoice/dummyData";

const keyList = {
    invoiceUpdate : 'INVOICE_UPDATE',
    invoiceDelete : 'INVOICE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        invoice:{},
        childOrders:[],
        siteInfo:{},
        mainOrderId:"",
        invoiceLoading: false,
        invoiceError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_INVOICE (state, newInvoice) {
            state.invoice = newInvoice;
        },
        SET_CHILD_ORDER (state, newOrder) {
            state.childOrders = newOrder;
        },
        SET_SITE_INFO (state, info) {
            state.siteInfo = info;
        },
        SET_MAIN_ID (state, id) {
            state.mainOrderId = id;
        },

        INVOICE_LOADING (state, loading) {
            state.invoiceLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async fetchInvoiceById ({ commit },payload) {
            commit('INVOICE_LOADING', true);
            commit('INVOICE_ERROR', false);
            try{
                let result = await axios.get(V1API.order_details_by_id+'/'+payload.id);
                let response=decode(result.data)
                commit('SET_INVOICE', response.data.order_data);
                commit('SET_CHILD_ORDER', response.data.child_order_data);
                commit('SET_SITE_INFO', response.data.site_info);
                commit('SET_MAIN_ID', response.data.main_order_id);


            } catch(error) {
                commit('INVOICE_ERROR', true);
            }
            commit('INVOICE_LOADING', false);
        },
        async updateOrder({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.invoiceUpdate,
                async () => {

                    response= await axios.post(V1API.order_status_update+'/'+payload.order_id+'/'+payload.new_status).then(result=>{
                        let data=decode(result.data)
                        return data
                    })
                    dispatch('fetchInvoiceById',{id:payload.order_id});
                }
            )
            return response
        },


    },
    getters: {}
}
