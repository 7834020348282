import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    shippingWithdrawalUpdate: 'SHIPPING_WITHDRAWAL_UPDATE',
    shippingWithdrawalDelete: 'SHIPPING_WITHDRAWAL_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        shippingWithdrawal: [],
        shippingWithdrawalLoading: false,
        totalCount: 0,
        shippingWithdrawalError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,
        SET_SHIPPING_WITHDRAWAL(state, newFinance) {
            state.shippingWithdrawal = newFinance;
        },
        SET_TOTAL(state, count) {
            state.totalCount = count
        },
        SHIPPING_WITHDRAWAL_LOADING(state, loading) {
            state.shippingWithdrawalLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async fetchShippingWithdrawalLog({commit}, payload) {
            commit('SHIPPING_WITHDRAWAL_LOADING', true);
            commit('SHIPPING_WITHDRAWAL_ERROR', false);
            try {
                let result = await axios.get(V1API.shipping_withdrawal_paginated + "?count=" + payload.count + "&page=" + payload.page);
                let response = decode(result.data)
                commit('SET_SHIPPING_WITHDRAWAL', response.results);
                commit('SET_TOTAL', response.count);
            } catch (error) {
                commit('SHIPPING_WITHDRAWAL_ERROR', true);
            }
            commit('SHIPPING_WITHDRAWAL_LOADING', false);
        },
        async fetchShippingWithdrawLogByID({commit}, payload) {
            commit('SHIPPING_WITHDRAWAL_LOADING', true);
            commit('SHIPPING_WITHDRAWAL_ERROR', false);
            try {
                let result = await axios.get(V1API.shipping_withdrawal_paginated_by_id + '/' + payload.business_id + "?count=" + payload.count + "&page=" + payload.page);
                let response = decode(result.data)
                commit('SET_SHIPPING_WITHDRAWAL', response.results);
                commit('SET_TOTAL', response.count);


            } catch (error) {
                commit('SHIPPING_WITHDRAWAL_ERROR', true);
            }
            commit('SHIPPING_WITHDRAWAL_LOADING', false);
        },

        async updateShippingWithdrawReq({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let magic = encode(payload)
                let response = await axios.get(V1API.shipping_withdrawal_request + '/' + payload.id + '/' + payload.status).then(result => {
                    let data = decode(result.data)
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchShippingWithdrawalLog', {count: 10, page: 1})
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },


    },
    getters: {}
}

