import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    reportUpdate : 'REPORT_UPDATE',
    reportDelete : 'REPORT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_REPORT (state, newReport) {
            state.productCategories = newReport;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async fetchOrderReport ({ commit },payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let result = await axios.get(V1API.order_list_report+'?from_date='+payload.start_timestamp+'&to_date='+payload.end_timestamp);
                let response=decode(result.data)
                window.open(response.file_url)

            } catch(error) {
                commit('SUBMIT_ERROR', true)
            }
            commit('SUBMIT_LOADING', false);
        },


    },
    getters: {}
}
