export default [

    {
        path: '/dashboard/coupon-management/coupon-list',
        name: 'coupon-list',
        component: () => import('@/views/pages/nextcart/databaseManagement/Coupon.vue'),
        meta:{
            codename:'view_couponlist'
        }

    },

]
