import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    areaUpdate : 'AREA_UPDATE',
    areaDelete : 'AREA_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        area: [],

        areaLoading: false,
        areaError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_AREA (state, area) {
            state.area = area;
        },

        AREA_LOADING (state, loading) {
            state.areaLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateArea({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.areaUpdate,
                async () => {

                    let magic=encode({name:payload.name})
                    response= await axios.post(V1API.area_edit+'/'+payload.area_id,magic.encodedPayload,magic.config).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchArea');

                }
            )
            return response
        },
        async toggleAreaHub({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.areaUpdate,
                async () => {

                    response= await axios.get(V1API.area_has_hub+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchArea');

                }
            )
            return response
        },

        async deleteArea({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.areaDelete,
                async () => {
                    response= await axios.delete(V1API.area_delete+'/'+payload.area_id).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    });
                    dispatch('fetchArea');
                }
            )

            return response
        },

        async fetchArea ({ commit }) {
            commit('AREA_LOADING', true);
            commit('AREA_ERROR', false);
            try{
                let result = await axios.get(V1API.area_get);
                let response=decode(result.data)

                commit('SET_AREA', response.data);

            } catch(error) {
                commit('AREA_ERROR', true);
            }
            commit('AREA_LOADING', false);
        },
        async fetchAreaByState({ commit },payload) {
            commit('AREA_LOADING', true);
            commit('AREA_ERROR', false);
            try{

                let result = await axios.get(V1API.area_by_state_get+'/'+ payload.state_id);
                let response=decode(result.data)
                commit('SET_AREA', response.data);
            } catch(error) {
                commit('AREA_ERROR', true);
            }
            commit('AREA_LOADING', false);
        },




        async addArea({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.area_add,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchArea')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
