import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";
import {retina} from "leaflet/src/core/Browser";

const keyList = {
    userUpdate : 'USER_UPDATE',
    userDelete : 'USER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        user: {},
        userLoading: false,
        userError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_USER (state, newUser) {
            state.user = newUser;
        },

        USER_LOADING (state, loading) {
            state.userLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async fetchUserInfo ({ commit },payload) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.user_info_get);
                let response=decode(result.data)
                console.log(response)
                commit('SET_USER', response.data);

            } catch(error) {
                commit('USER_ERROR', true);
            }
            commit('USER_LOADING', false);
        },
        async userUpdate ({ commit, dispatch },payload) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let magic=encode(payload)

                let result = await axios.post(V1API.user_info_update,magic.encodedPayload,magic.config);
                let response=decode(result.data)
                dispatch('fetchUserInfo')
                return response

            } catch(error) {
                commit('USER_ERROR', true);
            }
            commit('USER_LOADING', false);
        },
           async userChangePassword ({ commit, dispatch },payload) {
                    commit('USER_LOADING', true);
                    commit('USER_ERROR', false);
                    try{
                        console.log(payload)
                        let magic=encode(payload)

                        let result = await axios.post(V1API.user_pass_update,magic.encodedPayload,magic.config);
                        let response=decode(result.data)
                        dispatch('fetchUserInfo')
                        return response

                    } catch(error) {
                        commit('USER_ERROR', true);
                    }
                    commit('USER_LOADING', false);},

    },
    getters: {}
}
