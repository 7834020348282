import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    productCategoryUpdate : 'PRODUCT_CATEGORY_UPDATE',
    productCategoryDelete : 'PRODUCT_CATEGORY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        productCategories: [],
        commissionList:[],
        productCategoryLoading: false,
        productCategoryError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_PRODUCT_CATEGORY (state, newProductCategory) {
            state.productCategories = newProductCategory;
        },
        SET_PRODUCT_CATEGORY_COMMISSION (state, newCommissionList) {
            state.commissionList = newCommissionList;
        },

        PRODUCT_CATEGORY_LOADING (state, loading) {
            state.productCategoryLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateProductCategory({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.productCategoryUpdate,
                async () => {
                    let magic=encode(payload.data)
                    response= await axios.post(V1API.product_category_update+'/'+payload.category_id,magic.encodedPayload, magic.config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchProductCategory');

                }
            )
            return response
        },

        async deleteProductCategory({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.productCategoryDelete,
                async () => {
                    response= await axios.delete(V1API.product_category_delete+'/'+payload.category_id).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchProductCategory');
                }
            )

            return response
        },
        async toggleCategoryFeature({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.productCategoryUpdate,
                async () => {
                    response= await axios.get(V1API.product_feature_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchProductCategory');

                }
            )
            return response
        },
        async updateCategoryCommission({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.productCategoryUpdate,
                async () => {
                    let magic=encode(payload.payload)
                    response= await axios.post(V1API.category_commission_update+'/'+payload.id,magic.encodedPayload, magic.config).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    })
                    dispatch('fetchCommissionCategory');
                }
            )
            return response
        },

        async fetchProductCategory ({ commit },payload) {
            commit('PRODUCT_CATEGORY_LOADING', true);
            commit('PRODUCT_CATEGORY_ERROR', false);
            try{
                let result = await axios.get(V1API.product_category_read);
                let response=decode(result.data)
                console.log(response)
                commit('SET_PRODUCT_CATEGORY', response.data);

            } catch(error) {
                commit('PRODUCT_CATEGORY_ERROR', true);
            }
            commit('PRODUCT_CATEGORY_LOADING', false);
        },
        async fetchCommissionCategory ({ commit }) {
            commit('PRODUCT_CATEGORY_LOADING', true);
            commit('PRODUCT_CATEGORY_ERROR', false);
            try{

                let result = await axios.get(V1API.category_commission_read);
                let response=decode(result.data)
                commit('SET_PRODUCT_CATEGORY_COMMISSION', response.data);

            } catch(error) {
                commit('PRODUCT_CATEGORY_ERROR', true);
            }
            commit('PRODUCT_CATEGORY_LOADING', false);
        },

        async addProductCategory({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.product_category_add,magic.encodedPayload,magic.config).then(result=>{

                    let data=decode(result.data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchProductCategory')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
