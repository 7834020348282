import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    themeUpdate : 'THEME_UPDATE',
    themeDelete : 'THEME_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        theme: [],
        themeLoading: false,
        themeError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_THEME (state, theme) {
            state.theme = theme;
        },

        THEME_LOADING (state, loading) {
            state.themeLoading = loading;
        },

        THEME_ERROR (state, hasError) {
            state.themeError = hasError;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateTheme({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.themeUpdate,
                async () => {
                    let payloadData={
                        'theme_type':payload.theme_type,
                        'git_link':payload.git_link,
                        'business_type':payload.business_type,
                        'name':payload.name,
                        'theme_id':payload.theme_id,

                    }
                    if (payload.image!=undefined){
                        payloadData.image=payload.image
                    }
                    console.log(payloadData)
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.theme_edit+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchTheme');

                }
            )
            return response
        },



        async deleteTheme({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.themeDelete,
                async () => {
                    response = await axios.delete(V1API.theme_delete+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchTheme');
                }
            )

            return response
        },

        async fetchTheme({ commit }) {
            commit('THEME_LOADING', true);
            commit('THEME_ERROR', false);
            try{

                let result = await axios.get(V1API.theme_get);
                let response=decode(result.data)
                console.log(response.data)

                commit('SET_THEME', response.data);

            }catch(error) {

                commit('THEME_ERROR', true);

            }
            commit('THEME_LOADING', false);
        },
        async addTheme({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.theme_add,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchTheme')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async toggleThemeFeatureStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.themeUpdate,
                async () => {
                    response= await axios.get(V1API.theme_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchTheme');

                }
            )
            return response
        },


    },
    getters: {}
}
