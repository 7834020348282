import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    adsImageUpdate: 'ADS_IMAGE_UPDATE',
    adsImageDelete: 'ADS_IMAGE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        adsImageTop: [],
        adsImageMiddle: [],
        adsImageBottom: [],
        adsImageLoading: false,
        adsImageError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,

        SET_ADS_IMAGE_TOP(state, newAdsImage) {
            state.adsImageTop = newAdsImage;
        },
        SET_ADS_IMAGE_MIDDLE(state, newAdsImage) {
            state.adsImageMiddle = newAdsImage;
        },
        SET_ADS_IMAGE_BOTTOM(state, newAdsImage) {
            state.adsImageBottom = newAdsImage;
        },

        ADS_IMAGE_LOADING(state, loading) {
            state.adsImageLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateAdsImageBottom({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.adsImageUpdate,
                async () => {
                    let magic = encode(payload.payload)
                    response = await axios.post(V1API.ads_image_update_3 + '/' + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchBottomAdsImage');

                }
            )
            return response
        },

        async updateAdsImageTop({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.adsImageUpdate,
                async () => {
                    let magic = encode(payload.payload)
                    response = await axios.post(V1API.ads_image_update_1 + '/' + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchTopAdsImage');

                }
            )
            return response
        },
       async updateAdsImageMiddle({commit, dispatch}, payload) {
                let response = '';
                await performNetworkCall(
                    commit,
                    keyList.adsImageUpdate,
                    async () => {
                        let magic = encode(payload.payload)
                        response = await axios.post(V1API.ads_image_update_2 + '/' + payload.id, magic.encodedPayload, magic.config).then(result => {
                            let data = decode(result.data)
                            return data
                        })

                        dispatch('fetchMiddleAdsImage');

                    }
                )
                return response
            },


        async fetchTopAdsImage({commit}, payload) {
            commit('ADS_IMAGE_LOADING', true);
            commit('ADS_IMAGE_ERROR', false);
            try {
                let result = await axios.get(V1API.ads_image_read_1);
                let response = decode(result.data)
                console.log(response)
                commit('SET_ADS_IMAGE_TOP', response.data);

            } catch (error) {
                commit('ADS_IMAGE_ERROR', true);
            }
            commit('ADS_IMAGE_LOADING', false);
        },

        async fetchMiddleAdsImage({commit}, payload) {
            commit('ADS_IMAGE_LOADING', true);
            commit('ADS_IMAGE_ERROR', false);
            try {
                let result = await axios.get(V1API.ads_image_read_2);
                let response = decode(result.data)
                console.log(response)
                commit('SET_ADS_IMAGE_MIDDLE', response.data);

            } catch (error) {
                commit('ADS_IMAGE_ERROR', true);
            }
            commit('ADS_IMAGE_LOADING', false);
        },

        async fetchBottomAdsImage({commit}, payload) {
            commit('ADS_IMAGE_LOADING', true);
            commit('ADS_IMAGE_ERROR', false);
            try {
                let result = await axios.get(V1API.ads_image_read_3);
                let response = decode(result.data)
                console.log(response)
                commit('SET_ADS_IMAGE_BOTTOM', response.data);

            } catch (error) {
                commit('ADS_IMAGE_ERROR', true);
            }
            commit('ADS_IMAGE_LOADING', false);
        },


    },
    getters: {}
}
