import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    shippingFinanceUpdate: 'SHIPPING_FINANCE_UPDATE',
    shippingFinanceDelete: 'SHIPPING_FINANCE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        shippingFinance: [],
        shippingFinanceLoading: false,
        totalCount:0,
        summary:{},
        shippingFinanceError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,
        SET_SHIPPING_FINANCE(state, newFinance) {
            state.shippingFinance = newFinance;
        },
        SET_TOTAL(state,count){
            state.totalCount=count
        },
        SET_SUMMARY(state,newSummary){
            state.summary=newSummary
        },
        SHIPPING_FINANCE_LOADING(state, loading) {
            state.shippingFinanceLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {


        async fetchShippingFinance({commit},payload) {
            commit('SHIPPING_FINANCE_LOADING', true);
            commit('SHIPPING_FINANCE_ERROR', false);
            try {
                let result = await axios.get(V1API.shipping_finance_paginated+ "?count=" + payload.count +"&page="+payload.page);
                let response = decode(result.data)
                commit('SET_SHIPPING_FINANCE', response.results);
                commit('SET_TOTAL', response.count);
                commit('SET_SUMMARY', response.summary);


            } catch (error) {
                commit('SHIPPING_FINANCE_ERROR', true);
            }
            commit('SHIPPING_FINANCE_LOADING', false);
        },
     async fetchShippingFinanceByBusiness({commit},payload) {
            commit('SHIPPING_FINANCE_LOADING', true);
            commit('SHIPPING_FINANCE_ERROR', false);
            try {
                let result = await axios.get(V1API.shipping_finance_paginated+'/'+payload.business_id+ "?count=" + payload.count +"&page="+payload.page);
                let response = decode(result.data)
                commit('SET_SHIPPING_FINANCE', response.results);
                commit('SET_TOTAL', response.count);
                commit('SET_SUMMARY', response.summary);


            } catch (error) {
                commit('SHIPPING_FINANCE_ERROR', true);
            }
            commit('SHIPPING_FINANCE_LOADING', false);
        },




    },
    getters: {}
}
