import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import {decode, encode} from "@/store/dataMagic";


const keyList = {
    businessOnboardUpdate : 'BUSINESS_ONBOARD_UPDATE',
    businessOnboardDelete : 'BUSINESS_ONBOARD_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_BUSINESS (state, business) {
            state.businesses= business;
        },
        SET_BUSINESS_SIMPLE (state, business) {
            state.businessSimple= business;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {





        async addBusiness({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.business_create,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    console.log(data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async createDeploymentUrl({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.deploy_urls_create,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    console.log(data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async selectTheme({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let data_tran= {
                    user: payload.user
                }
                let magic=encode(data_tran)
                let response = await axios.post(V1API.select_theme+payload.theme_id,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    console.log(data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async selectSubscription({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                console.log(payload.user)
                let data_tran= {
                    user: payload.user
                }
                let magic=encode(data_tran)
                let response = await axios.post(V1API.select_subscription+payload.subscription_id,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    console.log(data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addSubscriber({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                console.log(payload)
                let response = await axios.post(V1API.subscriber_create,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    console.log(data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async deployInstanceOnServer({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                console.log(payload)
                let response = await axios.post(V1API.deploy_instance,magic.encodedPayload, magic.config).then(result=>{
                    let data=decode(result.data)
                    console.log(data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
