export default [
    {
        path: '/dashboard/subscription/permission/group',
        name: 'permission-group',
        component: () => import('@/views/pages/nextcart/subscriptionManagement/permissionGroup/PermissionGroupTable.vue'),
        meta:{
            codename:'view_permissiongroup'
        }

    },
    {
        path: '/dashboard/subscription/permission/list',
        name: 'permission-list',
        component: () => import('@/views/pages/nextcart/subscriptionManagement/permission/PermissionTable.vue'),
        meta:{
            codename:'view_permissionlist'
        }

    },

]
