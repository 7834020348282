export default [
    {
        path: '/dashboard/theme-management/types',
        name: 'theme-type',
        component: () => import('@/views/pages/nextcart/themeManagement/ThemeTypesTable.vue'),
        meta:{
            codename:'view_themetypes'
        }

    },
    {
        path: '/dashboard/theme-management/themes',
        name: 'theme',
        component: () => import('@/views/pages/nextcart/themeManagement/ThemeTable.vue'),
        meta:{
            codename:'view_theme'
        }
    },
    // {
    //     path: '/dashboard/database/state',
    //     name: 'database-state',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/StateTable'),
    //     meta:{
    //         codename:'view_state'
    //     }
    // },
    // {
    //     path: '/dashboard/database/country',
    //     name: 'database-country',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/CountryTable'),
    //     meta:{
    //         codename:'view_country'
    //
    //     }
    //
    // }
]
