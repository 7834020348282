export default [
    {
        path: '/dashboard/subscription/day-limit',
        name: 'day-limit',
        component: () => import('@/views/pages/nextcart/subscriptionManagement/limit/DayLimitTable.vue'),
        meta:{
            codename:'view_daylimit'
        }

    },


]
