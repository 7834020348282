export default [
    {
        path: '/dashboard/subscription/permission/listing',
        name: 'permission-listing',
        component: () => import('@/views/pages/nextcart/subscriptionManagement/permission_listing/PermisionListingTable.vue'),
        meta:{
            codename:'view_permissionlisting'
        }

    },


]
