export default [
    {
        path: '/dashboard/subscription/plan/list',
        name: 'plan-list',
        component: () => import('@/views/pages/nextcart/subscriptionManagement/plan/PlanTable.vue'),
        meta:{
            codename:'view_planlist'
        }

    }

]
