export default [
    {
        path: '/dashboard/server-management/server-list',
        name: 'server-list',
        component: () => import('@/views/pages/nextcart/serverManagement/ServerListTable.vue'),
        meta:{
            codename:'view_serverlist'
        }

    },
    {   path: '/dashboard/server-management/deploy-list',
        name: 'deploy-list',
        component: () => import('@/views/pages/nextcart/serverManagement/DeployementUrlTable.vue'),
        meta:{
            codename:'view_deploymenturl'
        }

    },
    // {
    //     path: '/dashboard/theme-management/themes',
    //     name: 'theme',
    //     component: () => import('@/views/pages/nextcart/themeManagement/ThemeTable.vue'),
    //     meta:{
    //         codename:'view_theme'
    //     }
    // },
    // {
    //     path: '/dashboard/database/state',
    //     name: 'database-state',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/StateTable'),
    //     meta:{
    //         codename:'view_state'
    //     }
    // },
    // {
    //     path: '/dashboard/database/country',
    //     name: 'database-country',
    //     component: () => import('@/views/pages/nextcart/databaseManagement/CountryTable'),
    //     meta:{
    //         codename:'view_country'
    //
    //     }
    //
    // }
]
