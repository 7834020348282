import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    planUpdate: 'PLAN_UPDATE',
    planDelete: 'PLAN_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        plan: [],
        planLoading: false,
        planError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations: {
        ...mutations,
        SET_PLAN(state, plan) {
            state.plan = plan;
        },

        PLAN_LOADING(state, loading) {
            state.planLoading = loading;
        },

        PLAN_ERROR(state, hasError) {
            state.planError = hasError;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updatePlan({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.planUpdate,
                async () => {

                    let magic = encode(payload)
                    response = await axios.patch(V1API.plan_edit + "/" + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data.message
                    })

                    dispatch('fetchPlan');

                }
            )
            return response
        },


        async deletePlan({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.planDelete,
                async () => {
                    response = await axios.delete(V1API.plan_delete + '/' + payload).then(result => {
                        let data = decode(result.data)

                        return data.message

                    });
                    dispatch('fetchPlan');
                }
            )

            return response
        },

        async fetchPlan({commit}) {
            commit('PLAN_LOADING', true);
            commit('PLAN_ERROR', false);
            try {

                let result = await axios.get(V1API.plan_get);
                let response = decode(result.data)


                commit('SET_PLAN', response.data);

            } catch (error) {

                commit('PLAN_ERROR', true);

            }
            commit('PLAN_LOADING', false);
        },
        async addPlan({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let magic = encode(payload)
                let response = await axios.post(V1API.plan_add, magic.encodedPayload, magic.config).then(result => {
                    let data = decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchPlan')
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
