export default [
    {
        path: '/dashboard/subscription/pricing/list',
        name: 'pricing-list',
        component: () => import('@/views/pages/nextcart/subscriptionManagement/pricing/PricingTable.vue'),
        meta:{
            codename:'view_pricinglist'
        }

    },
    {
        path: '/dashboard/subscription/selected/list',
        name: 'selected-pricing-list',
        component: () => import('@/views/pages/nextcart/subscriptionManagement/pricing/SelectedPricingTable.vue'),
        meta:{
            codename:'view_pricinglist'
        }

    }

]
