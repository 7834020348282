import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    couponUpdate : 'COUPON__UPDATE',
    couponDelete : 'COUPON__DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        coupon: [],
        couponLoading: false,
        couponError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,
        ...store
    },
    mutations : {
        ...mutations,
        SET_COUPON (state, newCoupon) {
            state.coupon = newCoupon;
        },

        COUPON_LOADING (state, loading) {
            state.couponLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateCoupon({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.couponUpdate,
                async () => {
                    let magic=encode(payload.payload)
                    response= await axios.patch(V1API.coupon_update+'/'+payload.id,magic.encodedPayload, magic.config).then(result=>{

                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchCoupon');

                }
            )
            return response
        },
        async toggleCouponStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.couponUpdate,
                async () => {
                    response= await axios.post(V1API.coupon_status_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchCoupon');

                }
            )
            return response
        },

        async deleteCoupon({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.couponDelete,
                async () => {
                    response= await axios.delete(V1API.coupon_delete+"/"+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchCoupon');
                }
            )

            return response
        },

        async fetchCoupon ({ commit },payload) {
            commit('COUPON_LOADING', true);
            commit('COUPON_ERROR', false);
            try{
                let result = await axios.get(V1API.coupon_read);
                let response=decode(result.data)
                commit('SET_COUPON', response.data);

            } catch(error) {
                commit('COUPON_ERROR', true);
            }
            commit('COUPON_LOADING', false);
        },

        async addCoupon({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.coupon_add,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCoupon')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
