import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";
import dummyResponse from "@/store/order/dummyResponse";

const keyList = {
    orderUpdate: 'ORDER_UPDATE',
    orderDelete: 'ORDER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        sellerOrders: [],
        orderLoading: false,
        nextPage: null,
        prevPage: null,
        totalCount:0,
        orderError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,

        SET_SELLER_ORDERS(state, newOrder) {
            state.sellerOrders = newOrder
        },
        SET_NEXT_PAGE(state, page) {
            state.nextPage = page
        },
        SET_PREV_PAGE(state, page) {
            state.prevPage = page
        },
        SET_TOTAL_ORDER(state,count){
            state.totalCount=count
        },

        ORDER_LOADING(state, loading) {
            state.orderLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async fetchOrderBySeller({commit}, payload) {
            commit('ORDER_LOADING', true);
            commit('ORDER_ERROR', false);
            try {
                let result = await axios.get(V1API.order_by_seller + '/' + payload.id + "?count=" + payload.count +"&page="+payload.page);
                let response = decode(result.data)
                commit('SET_SELLER_ORDERS', response.results);
                // commit('SET_NEXT_PAGE', response.next);
                // commit('SET_PREV_PAGE', response.previous);
                commit('SET_TOTAL_ORDER', response.count);

            } catch (error) {
                commit('ORDER_ERROR', true);
            }
            commit('ORDER_LOADING', false);
        },
        async filterOrder({commit}, payload) {
            commit('ORDER_LOADING', true);
            commit('ORDER_ERROR', false);
            try {
                let result = await axios.post(V1API.order_filter +  "?count=" + payload.count +"&page="+payload.page,payload.payload);
                let response = decode(result.data)
                commit('SET_SELLER_ORDERS', response.results);
                commit('SET_NEXT_PAGE', response.next);
                commit('SET_PREV_PAGE', response.previous);
                commit('SET_TOTAL_ORDER', response.count);

            } catch (error) {
                commit('ORDER_ERROR', true);
            }
            commit('ORDER_LOADING', false);
        },


    },
    getters: {}
}
