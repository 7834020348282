export default [
   {

        path: '/dashboard/fx-rate/settings',
        name: 'fx-rate-settings',
        component: () => import('@/views/pages/nextcart/fx-rate/fxRateModule.vue'),
        meta:{
            codename:'view_fxSettings'
        }

    },


]
