import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    pricingUpdate: 'PRICING_UPDATE',
    pricingDelete: 'PRICING_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        pricing: [],
        selected_pricing: [],
        pricingLoading: false,
        pricingError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations: {
        ...mutations,
        SET_PRICING(state, pricing) {
            state.pricing = pricing;
        },
        SET_SELECTED_PRICING(state, pricing) {

            state.selected_pricing = pricing;
        },

        PRICING_LOADING(state, loading) {
            state.pricingLoading = loading;
        },

        PRICING_ERROR(state, hasError) {
            state.pricingError = hasError;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updatePricing({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.pricingUpdate,
                async () => {

                    let magic = encode(payload)
                    response = await axios.patch(V1API.pricing_edit + "/" + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data.message
                    })

                    dispatch('fetchPricing');

                }
            )
            return response
        },


        async deletePricing({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.pricingDelete,
                async () => {
                    response = await axios.delete(V1API.pricing_delete + '/' + payload).then(result => {
                        let data = decode(result.data)

                        return data.message

                    });
                    dispatch('fetchPricing');
                }
            )

            return response
        },
        async deleteSelectedPricing({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.pricingDelete,
                async () => {
                    response = await axios.delete(V1API.selected_subscription_delete + '/' + payload).then(result => {
                        let data = decode(result.data)

                        return data.message

                    });
                    dispatch('fetchSelectedPricing');
                }
            )

            return response
        },

        async fetchPricing({commit}) {
            commit('PRICING_LOADING', true);
            commit('PRICING_ERROR', false);
            try {

                let result = await axios.get(V1API.pricing_get);
                let response = decode(result.data)


                commit('SET_PRICING', response.data);

            } catch (error) {

                commit('PRICING_ERROR', true);

            }
            commit('PRICING_LOADING', false);
        },
        async fetchSelectedPricing({commit}) {
            commit('PRICING_LOADING', true);
            commit('PRICING_ERROR', false);
            try {

                let result = await axios.get(V1API.selected_subscription_all);
                let response = decode(result.data)



                commit('SET_SELECTED_PRICING', response.data);

            } catch (error) {

                commit('PRICING_ERROR', true);

            }
            commit('PRICING_LOADING', false);
        },
        async addPricing({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let magic = encode(payload)
                let response = await axios.post(V1API.pricing_add, magic.encodedPayload, magic.config).then(result => {
                    let data = decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchPricing')
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
