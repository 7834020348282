
import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    aboutUsUpdate: 'ABOUT_US_UPDATE',
    aboutUsDelete: 'ABOUT_US_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        aboutUs:{},
        aboutUsLoading: false,
        aboutUsError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,

        SET_ABOUT_US(state, newAboutUs) {
            state.aboutUs = newAboutUs;
        },
        ABOUT_US_LOADING(state, loading) {
            state.aboutUsLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {


        async updateAboutUs({commit, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.aboutUsUpdate,
                async () => {
                    let magic = encode(payload)
                    response = await axios.post(V1API.about_us_update, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchAboutUs');

                }
            )
            return response
        },
        async fetchAboutUs({commit}, payload) {
            commit('ABOUT_US_LOADING', true);
            commit('ABOUT_US_ERROR', false);
            try {
                let result = await axios.get(V1API.about_us_read);
                let response = decode(result.data)
                commit('SET_ABOUT_US', response.data);

            } catch (error) {
                commit('ABOUT_US_ERROR', true);
            }
            commit('ABOUT_US_LOADING', false);
        },


    },
    getters: {}
}
