import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    dayLimitUpdate: 'DAY_LIMIT_UPDATE',
    dayLimitDelete: 'DAY_LIMIT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        dayLimit: [],
        dayLimitLoading: false,
        dayLimitError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations: {
        ...mutations,
        SET_DAY_LIMIT(state, dayLimit) {
            state.dayLimit = dayLimit;
        },

        DAY_LIMIT_LOADING(state, loading) {
            state.dayLimitLoading = loading;
        },

        DAY_LIMIT_ERROR(state, hasError) {
            state.dayLimitError = hasError;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateDayLimit({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.dayLimitUpdate,
                async () => {

                    let magic = encode(payload)
                    response = await axios.patch(V1API.day_limit_edit + "/" + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data.message
                    })

                    dispatch('fetchDayLimit');

                }
            )
            return response
        },


        async deleteDayLimit({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.dayLimitDelete,
                async () => {
                    response = await axios.delete(V1API.day_limit_delete + '/' + payload).then(result => {
                        let data = decode(result.data)

                        return data.message

                    });
                    dispatch('fetchDayLimit');
                }
            )

            return response
        },

        async fetchDayLimit({commit}) {
            commit('DAY_LIMIT_LOADING', true);
            commit('DAY_LIMIT_ERROR', false);
            try {

                let result = await axios.get(V1API.day_limit_get);
                let response = decode(result.data)
                console.log(response)

                commit('SET_DAY_LIMIT', response.data);

            } catch (error) {

                commit('DAY_LIMIT_ERROR', true);

            }
            commit('DAY_LIMIT_LOADING', false);
        },
        async addDayLimit({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let magic = encode(payload)
                let response = await axios.post(V1API.day_limit_add, magic.encodedPayload, magic.config).then(result => {
                    let data = decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchDayLimit')
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
