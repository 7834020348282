/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from 'axios'
import {V1API} from '@/http/APIPath';
import {decode, encode} from "@/store/dataMagic";

export default {

    async addUser({commit}, payload) {
        commit("SET_USER_ADD_LOADING", true);
        commit("SET_USER_ADD_ERROR", false);
        try{
            let magic=encode(payload)
            let response = await axios.post(V1API.user_create,magic.encodedPayload, magic.config).then(result=>{
                let data=decode(result.data)
                if(data.errors!=null){
                   throw data.errors
               }
                return data
            });
            commit("SET_USER_ADD_SUCCESS", true);
            return response
        } catch (error) {

            commit("SET_USER_ADD_ERROR", true);
            return error
        } finally {
            commit("SET_USER_ADD_LOADING", false);
        }
    }
}
