import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
  financeUpdate: 'FINANCE_UPDATE',
  financeDelete: 'FINANCE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
  namespaced: true,
  state: {
    finance: [],
    financeLoading: false,
    totalCount:0,
    summary:{},
    financeError: false,
    submitLoading: false,
    submitError: false,
    submitSuccess: false,

    ...store
  },
  mutations: {
    ...mutations,
    SET_FINANCE(state, newFinance) {
      state.finance = newFinance;
    },
    SET_TOTAL(state,count){
      state.totalCount=count
    },
    SET_SUMMARY(state,newSummary){
      state.summary=newSummary
    },
    FINANCE_LOADING(state, loading) {
      state.financeLoading = loading;
    },
    SUBMIT_LOADING(state, loading) {
      state.submitLoading = loading;
    },

    SUBMIT_ERROR(state, hasError) {
      state.submitError = hasError;
    },

    SUBMIT_SUCCESS(state, success) {
      state.submitSuccess = success;
    }
  },
  actions: {


    async fetchFinance({commit},payload) {
      commit('FINANCE_LOADING', true);
      commit('FINANCE_ERROR', false);
      try {
        let result = await axios.get(V1API.finance_paginated+ "?count=" + payload.count +"&page="+payload.page);
        let response = decode(result.data)
        commit('SET_FINANCE', response.results);
        commit('SET_TOTAL', response.count);
        commit('SET_SUMMARY', response.summary);


      } catch (error) {
        commit('FINANCE_ERROR', true);
      }
      commit('FINANCE_LOADING', false);
    },
    async fetchFinanceByAffiliate({commit},payload) {
      commit('FINANCE_LOADING', true);
      commit('FINANCE_ERROR', false);
      try {
        let result = await axios.get(V1API.finance_paginated+'/'+payload.user_id+ "?count=" + payload.count +"&page="+payload.page);
        let response = decode(result.data)
        console.log(response)
        commit('SET_FINANCE', response.results);
        commit('SET_TOTAL', response.count);
        commit('SET_SUMMARY', response.summary);


      } catch (error) {
        commit('FINANCE_ERROR', true);
      }
      commit('FINANCE_LOADING', false);
    },




  },
  getters: {}
}
