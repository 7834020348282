export default [
    {
        path: '/dashboard/subscription/feature/list',
        name: 'feature-list',
        component: () => import('@/views/pages/nextcart/subscriptionManagement/feature/FeatureTable.vue'),
        meta:{
            codename:'view_featurelist'
        }

    }

]
