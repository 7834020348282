import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    permissionGroupUpdate: 'PERMISSION_GROUP_UPDATE',
    permissionGroupDelete: 'PERMISSION_GROUP_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        permissionGroup: [],
        permissionGroupJson: [],
        permissionGroupLoading: false,
        permissionGroupError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations: {
        ...mutations,
        SET_PERMISSION_GROUP(state, permissionGroup) {
            state.permissionGroup = permissionGroup;
        },
        SET_PERMISSION_GROUP_JSON(state, permissionGroup) {
            state.permissionGroupJson = permissionGroup;
        },

        PERMISSION_GROUP_LOADING(state, loading) {
            state.permissionGroupLoading = loading;
        },

        PERMISSION_GROUP_ERROR(state, hasError) {
            state.permissionGroupError = hasError;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updatePermissionGroup({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.permissionGroupUpdate,
                async () => {

                    let magic = encode(payload)
                    response = await axios.patch(V1API.permission_group_edit + "/" + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data.message
                    })

                    dispatch('fetchPermissionGroup');

                }
            )
            return response
        },


        async deletePermissionGroup({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.permissionGroupDelete,
                async () => {
                    response = await axios.delete(V1API.permission_group_delete + '/' + payload).then(result => {
                        let data = decode(result.data)

                        return data.message

                    });
                    dispatch('fetchPermissionGroup');
                }
            )

            return response
        },

        async fetchPermissionGroup({commit}) {
            commit('PERMISSION_GROUP_LOADING', true);
            commit('PERMISSION_GROUP_ERROR', false);
            try {

                let result = await axios.get(V1API.permission_group_get);
                let response = decode(result.data)


                commit('SET_PERMISSION_GROUP', response.data);

            } catch (error) {

                commit('PERMISSION_GROUP_ERROR', true);

            }
            commit('PERMISSION_GROUP_LOADING', false);
        },
        async fetchPermissionGroupJson({commit}) {
            commit('PERMISSION_GROUP_LOADING', true);
            commit('PERMISSION_GROUP_ERROR', false);
            try {

                let result = await axios.get(V1API.permission_group_get_json);
                let response = decode(result.data)


                commit('SET_PERMISSION_GROUP_JSON', response.data);

            } catch (error) {

                commit('PERMISSION_GROUP_ERROR', true);

            }
            commit('PERMISSION_GROUP_LOADING', false);
        },
        async addPermissionGroup({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let magic = encode(payload)
                let response = await axios.post(V1API.permission_group_add, magic.encodedPayload, magic.config).then(result => {
                    let data = decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchPermissionGroup')
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
