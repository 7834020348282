/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {

    SET_USER_ADD_LOADING (state, isLoading) {
      state.userAddLoading = isLoading;
    },

    SET_USER_ADD_ERROR (state, hasError) {
      state.userAddError = hasError;
    },

    SET_USER_ADD_SUCCESS (state, success) {
      state.userAddSuccess = success;
    },


}
