export default [
    {
        path: '/dashboard/database/business/types',
        name: 'business-type',
        component: () => import('@/views/pages/nextcart/databaseManagement/BusinessTypesTable'),
        meta:{
            codename:'view_businesstypes'
        }

    },
    {
        path: '/dashboard/database/area',
        name: 'database-area',
        component: () => import('@/views/pages/nextcart/databaseManagement/AreaTable'),
        meta:{
            codename:'view_area'
        }
    },
    {
        path: '/dashboard/database/state',
        name: 'database-state',
        component: () => import('@/views/pages/nextcart/databaseManagement/StateTable'),
        meta:{
            codename:'view_state'
        }
    },
    {
        path: '/dashboard/database/country',
        name: 'database-country',
        component: () => import('@/views/pages/nextcart/databaseManagement/CountryTable'),
        meta:{
            codename:'view_country'

        }

    }  ,
    {
        path: '/dashboard/database/reserved-slug',
        name: 'reserved-slug',
        component: () => import('@/views/pages/nextcart/databaseManagement/ReservedSlugTable.vue'),
        meta:{
            codename:'view_reservedslug'

        }

    }
]
