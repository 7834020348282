import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    rolesUpdate : 'ROLES_UPDATE',
    rolesDelete : 'ROLES_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        roles: [],
        rolesLoading: false,
        rolesError: false,
        businessID:null,

        ...store
    },
    mutations : {
        ...mutations,
        SET_ROLES (state, roles) {
            state.roles = roles;
        },
        ROLES_LOADING (state, loading) {
            state.rolesLoading = loading;
        },
        ROLES_ERROR(state,error){
            state.rolesError=error;
        }
    },
    actions: {

        async fetchRoles({commit}) {
            commit('ROLES_LOADING', true);
            commit('ROLES_ERROR', false);
            try {
                let result = await axios.get(V1API.roles_get);
                let response=decode(result.data)
                commit('SET_ROLES', response.data);

            } catch (error) {
                commit('ROLES_ERROR', true);
            }
            commit('ROLES_LOADING', false);
        },
        async assignRoles({commit}, payload) {
            try {
                let magic=encode(payload)
                let response = await axios.post(V1API.assign_role, magic.encodedPayload, magic.config).then(result => {
                    let data=decode(result.data)
                    if (data.errors != null) {
                        throw data.errors
                    }
                    return data.message
                });
                return response
            } catch (error) {
                return error
            }

        }
    },
    getters: {}
}
