import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";
import dummyResponse from "@/store/cloudX/invoice/dummyData";

const keyList = {
    invoiceUpdate: 'PG_UPDATE',
    invoiceDelete: 'PG_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        pglog: [],
        pgHistory: [],
        pgLoading: false,
        pgError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,
        SET_PG_LOG(state, newPgLog) {
            state.pglog = newPgLog;
        },
        SET_PG_HISTORY(state, newPgHistory) {
            state.pgHistory = newPgHistory;
        },

        PG_ERROR(state, hasError) {
            state.pgError = hasError;
        },
        PG_LOADING(state, loading) {
            state.pgLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async fetchPgLog({commit}, payload) {
            commit('PG_LOADING', true);
            commit('PG_ERROR', false);
            try {
                let result = await axios.get(V1API.pg_log);
                let response = decode(result.data)
                commit('SET_PG_LOG', response.data);

            } catch (error) {
                commit('PG_ERROR', true);
            }
            commit('PG_LOADING', false);
        },
        async fetchPgHistroy({commit}, payload) {
            commit('PG_LOADING', true);
            commit('PG_ERROR', false);
            try {
                let result = await axios.get(V1API.pg_history);
                let response = decode(result.data)
                // console.log(response.data)
                commit('SET_PG_HISTORY', response.data);
            } catch (error) {
                commit('PG_ERROR', true);
            }
            commit('PG_LOADING', false);
        },

    },
    getters: {}
}
