export default [
    {
        path: '/dashboard/product-source',
        name: 'product-source',
        component: () => import('@/views/pages/nextcart/product_source/ProductSource.vue'),
        meta: {
            codename: "view_productsource"
        }
    }, {
        path: '/dashboard/product-source/distributor',
        name: 'product-source-distributor',
        component: () => import('@/views/pages/nextcart/product_source/ProductSourceDistributor.vue'),
        meta: {
            codename: "view_productsource_distributor"
        }
    },

]
