import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    paymentMethodUpdate : 'PAYMENT_METHOD__UPDATE',
    paymentMethodDelete : 'PAYMENT_METHOD__DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        paymentMethod: [],
        paymentMethodLoading: false,
        paymentMethodError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,
        ...store
    },
    mutations : {
        ...mutations,
        SET_PAYMENT_METHOD (state, newPaymentMethod) {
            state.paymentMethod = newPaymentMethod;
        },

        PAYMENT_METHOD_LOADING (state, loading) {
            state.paymentMethodLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updatePaymentMethod({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.paymentMethodUpdate,
                async () => {
                    let magic=encode(payload.payload)
                    response= await axios.post(V1API.payment_update+'/'+payload.id,magic.encodedPayload, magic.config).then(result=>{

                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchPaymentMethod');

                }
            )
            return response
        },
        async togglePaymentMethodStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.paymentMethodUpdate,
                async () => {
                    response= await axios.post(V1API.payment_status_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchPaymentMethod');

                }
            )
            return response
        },
        async toggleTestModeStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.paymentMethodUpdate,
                async () => {
                    response= await axios.post(V1API.payment_test_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchPaymentMethod');

                }
            )
            return response
        },

        async deletePaymentMethod({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.paymentMethodDelete,
                async () => {
                    response= await axios.delete(V1API.payment_delete+"/"+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    });
                    dispatch('fetchPaymentMethod');
                }
            )

            return response
        },

        async fetchPaymentMethod ({ commit },payload) {
            commit('PAYMENT_METHOD_LOADING', true);
            commit('PAYMENT_METHOD_ERROR', false);
            try{
                let result = await axios.get(V1API.payment_read);
                let response=decode(result.data)
                commit('SET_PAYMENT_METHOD', response.data);

            } catch(error) {
                commit('PAYMENT_METHOD_ERROR', true);
            }
            commit('PAYMENT_METHOD_LOADING', false);
        },

        async addPaymentMethod({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.payment_add,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPaymentMethod')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
